// src/Lesson.tsx
import React from 'react';
import WebSocketAudio from './WebSocketAudio';

const Lesson: React.FC = () => {
    return (
        <div>
            <WebSocketAudio />
        </div>
    );
};

export default Lesson;

